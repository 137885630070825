import React from 'react';

function FuncionalidadesLista(props){

    return <div className="bloco-funcionalidades-lista" id="funcionalidades">

        <div className="container">
            <h1 className="titulo-bloco"><span>&gt;</span> Funcionalidades</h1>

            <ul className="lista">
                <li>Interface web para cadastro e agendamento dos pacientes;</li>
                <li>Fórmulas magistrais apra crianças, adultos, gestantes, idosos e atletas;</li>
                <li>Rastreamento metabólico;</li>
                <li>Teia de inter-relações metabólicas interativa;</li>
                <li>Anamnese personalizada com ferramentas exclusivas;</li>
                <li>Avaliação antropométrica completa, com gráfico ilustrando a evolução de cada paciente;</li>
                <li>Solicitação de exames bioquimicos mais utilizados na área, é só clicar e imprimir na hora;</li>
                <li>Configuração da logo de cada profissional para sair impresso nas prescrições ou no aplicativo;</li>
                <li>Plano alimentar com gráfico de macronutrientes.</li>
            </ul>
        </div>
    </div>

}

export default FuncionalidadesLista;