import React from 'react';

import imagem from './../../assets/banners/shutterstock_136385225.jpg';

function BotaoExperimentar(props){


    return <div className=" bloco-experimentar">
        <div className=" topo">
            <div className="container">
                <a className="button cor-4" href="http://www.sistema.evolutts.com.br">Quero experimentar <span> grátis </span> por 15 dias.</a>
            </div>
        </div>
        <img className="imagem-banner" src={imagem} />
    </div>;
}


export default BotaoExperimentar;