import React from 'react';
import Slider from "react-slick";

import imagem1 from './../../assets/banners/shutterstock_478777894.jpg';

function Banners(){

    const settings = {
        arrows: false,
        dots: true,
        // appendDots: this.template.find(".banners .pontos")[0]
        appendDots: () => <ul>.</ul>
    };


    let banner1 ={
        background: `url(${imagem1}) center no-repeat`,
        backgroundSize: 'cover'
    };

    let heightBloco = {
        background: 'orange',
        height: 'calc(100vh - 400px)',
        minHeight: '400px'
    };

    return <div className="banners" id="inicio">
        <Slider className="blocos" {...settings}>
            <div className="bloco" id="bloco-slick">
                <div className="imagem" style={banner1}></div>
                <div className="descricao">
                    <p>A solução ideal para otimizar o seu atendimento.</p>
                </div>
            </div>

        </Slider>
        <div className="pontos">
        
        </div>
    </div>
}

export default Banners;