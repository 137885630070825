import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// imagens
import imagem1 from './../../assets/func1.png';
import imagem2 from './../../assets/func2.png';
import imagem3 from './../../assets/func3.png';
import imagem4 from './../../assets/func4.png';

function Funcionalidades(props){

    return <div className="container bloco-funcionalidades">
        <h1 className="titulo-funcionalidades">O <span>futuro</span> no<br />seu consultório.</h1>
        <h2 className="subtitulo-funcionalidades">
            Conheça algumas funcionalidades do software de nutrição mais prático e completo do Brasil.    
        </h2>

        <div className="funcionalidades">
            <div className="funcionalidade">
                <div className="imagem-funcionalidade">
                    <img src={imagem1} />
                </div>
                <p>Interface web para cadastro e agendamento dos pacientes</p>
            </div>
            <div className="funcionalidade">
                <div className="imagem-funcionalidade">
                    <img src={imagem2} />
                </div>
                <p>Fórmulas magistrais para crianças, adultos, gestantes, idosos e atletas</p>
            </div>
            <div className="funcionalidade">
                <div className="imagem-funcionalidade">
                    <img src={imagem3} />
                </div>
                <p>Avaliação antropométrica, com gráfico ilustrando a evolução de cada paciente</p>
            </div>
            <div className="funcionalidade">
                <div className="imagem-funcionalidade">
                    <img src={imagem4} />
                </div>
                <p>Plano alimentar com gráfico de macronutrientes</p>
            </div>
        </div>

        <p className="has-text-centered">
            <a className="button cor-4 botao-saiba-mais" href="#funcionalidades">
                <span className="sinal">
                    {/* <i className="fas fa-plus fa-fw"></i> */}
                    <FontAwesomeIcon className="icone fa-fw" size='2x' icon={["fas", 'plus']} />
                </span>
                <b>Saiba Mais! </b>Conheça todos os beneficios de trabalhar com a Evolutts.</a>
        </p>
    </div>

}

export default Funcionalidades;