import React from 'react';

import Cabecalho from './componentes/cabecalho/CabecalhoComponente';
import Banners from './componentes/banners/BannersComponente';
import Funcionalidades from './componentes/funcionalidades/FuncionalidadesComponente';
import BotaoExperimentar from './componentes/botaoExperimentar/BotaoExperimentarComponente';
import FuncionalidadesLista from './componentes/funcionalidadesLista/FuncionalidadesListaComponente';
import QuemSomos from './componentes/quemSomos/QuemSomosComponente';
import Cliente from './componentes/cliente/ClienteComponente';
import Rodape from './componentes/rodape/RodapeComponente';
import './PaginaInicio.scss';
import './PaginaRodape.scss';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PoliticaDePrivacidade from './componentes/PoliticaDePrivacidade';

function App (props){

    // document.title = 'Evolutts';

    return <div>
        

        <div className="t-pagina t-pagina-inicio">

            <Router>
                <Switch>
                    <Route exact path="/">
                        <>
                            <Cabecalho />
                            <Banners />
                            <div className="dividor-pagina container"></div>
                            <Funcionalidades />
                            <BotaoExperimentar />
                            <div className="dividor-pagina container"></div>
                            <FuncionalidadesLista />
                            <QuemSomos />
                            <Cliente />
                            <Rodape />
                        </>
                    </Route>
                    <Route exact path="/politica-de-privacidade">
                        <PoliticaDePrivacidade />
                    </Route>
                </Switch>
            </Router>

        </div>
        

    </div>;
}

export default App;
