import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// importa imagens
import logo1 from './../../assets/logo1.png';
import logo2 from './../../assets/logo2.png';

import './CabecalhoComponente.scss';

// cabeçalho
function Cabecalho(props){

    return <nav className="t-site-base t-cabecalho">
        <div className="menus">
            <div className="menu-1 container">
                <div className="esquerda">
                    <a className="redes-sociais" href="https://www.facebook.com/aevolutts" target="_blank">
                        <FontAwesomeIcon className="icone fa-fw" size='2x' icon={["fab", 'facebook-f']} />
                    </a>
                    <a className="redes-sociais" href="https://www.instagram.com/aevolutts/" target="_blank">
                        <FontAwesomeIcon className="icone fa-fw" size='2x' icon={["fab", 'instagram']} />
                    </a>
                </div>
                <div className="direita">
                    <a className="button cor-3" href="http://www.sistema.evolutts.com.br/">Faça seu login</a>
                    <a className="button cor-4" href="http://www.sistema.evolutts.com.br/?p=cadastrar">Inscreva-se</a>
                </div>
            </div>

            <div className="menu-2">
                <div className="container">
                    <div className="esquerda">
                        <a className="atalho-menu" href="#inicio">INíCIO</a>
                        <FontAwesomeIcon className="icone ponto fa-fw" size='2x' icon={["fas", 'circle']} />
                        <a className="atalho-menu" href="#sobre">SOBRE</a>
                    </div>
                    <div className="direita">
                        <a className="atalho-menu" href="#planos">PLANOS</a>
                        <FontAwesomeIcon className="icone ponto fa-fw" size='2x' icon={["fas", 'circle']} />
                        <a className="atalho-menu" href="#contato">CONTATO</a>
                    </div>
                </div>
            </div>
            <div className="emblema">
                <img className="logo-1" src={logo1} />
                <img className="logo-2" src={logo2} />
            </div>
        </div>
    </nav>;

}

export default Cabecalho;