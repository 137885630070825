import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo1 from './../../assets/logo1.png';

function Rodape(props){

    return <div className="t-site-base t-rodape" id="contato">
        <div className="info">
            <div className="container">
                <div className="">
                    <h1 className="titulo-1">Dúvidas? Entre<br /> em contato<br /> conosco.</h1>
                    <div className="redes-sociais">

                        <a href="https://www.facebook.com/aevolutts" target="_blank">
                            <span className="icone">
                                {/* <i className="fab fa-facebook-f fa-fw"></i> */}
                                <FontAwesomeIcon className="icone fa-fw" icon={["fab", 'facebook-f']} />
                            </span>
                            <span className="desc">/aevolutts</span>
                        </a>

                        <a href="https://www.instagram.com/aevolutts/" target="_blank">
                            <span className="icone">
                                {/* <i className="fab fa-instagram fa-fw"></i> */}
                                <FontAwesomeIcon className="icone fa-fw" icon={["fab", 'instagram']} />
                            </span>
                            <span className="desc">@aevolutts</span>
                        </a>

                        <a href="https://api.whatsapp.com/send?1=pt_br /&amp;phone=5548991021031" target="blank">
                            <span className="icone">
                                {/* <i className="fab fa-whatsapp fa-fw"></i> */}
                                <FontAwesomeIcon className="icone fa-fw" icon={["fab", 'whatsapp']} />
                            </span>
                            <span className="desc">48 <b>9 9102-1031</b></span>
                        </a>
                    </div>
                </div>
                <div className="logo-rodape">
                    <img src={logo1} />
                </div>
                <div className="menu">
                    <p><b>Evolutts</b></p>
                    <p>Solução para nutricionistas</p>

                    <div className="links">
                        <a href="#inicio">Inicio</a>
                        <a href="#sobre">Sobre</a>
                        <a href="#planos">Planos</a>
                        <a href="#contato">Contato</a>
                        <div className="espaco"></div>
                        <a href="http://www.sistema.evolutts.com.br //">Login</a>
                        <a href="http://www.sistema.evolutts.com.br //?p=cadastrar">Inscreva-se</a>
                    </div>
                </div>
            </div>

        </div>
        <div className="info-2">
            <div className="container">
                2018 © Todos os direitos reservados.
            </div>
        </div>
    </div>
}


export default Rodape;