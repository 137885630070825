import React from 'react';

import imagem from './../../assets/banners/banner2.jpg';

function QuemSomos(props){

    return <div className="bloco-quem-somos" id="sobre">
        <div className="imagem">
            <img src={imagem} />
        </div>
        <div className="textos">
            <h1 className="titulo-bloco">Quem somos?</h1>
            <p>A união de tecnologia avançada em plataforma web associada a expertise de sucesso de uma profissional nutricionista deram origem a este software. Evolutts vai muito além do que um software de atendimento nutricional e prescrição dietética, é uma ferramenta inovadora desenvolvida para otimizar a conduta nutricional em consultório.</p>
            <h1 className="titulo-bloco">Nossa missão.</h1>
            <p>Ser uma ferramenta de fácil aplicação em consultório, otimizando o tempo de consulta, auxiliando no diagnóstico nutricional e consequente prescrição dietética do profissional nutricionista de maneira muito mais assertiva.</p>

            <div className="dividor-pagina container"></div>
        </div>
    </div>
}

export default QuemSomos;