import React from 'react';

function Cliente(props){

    return <div className="bloco-cliente" id="planos">

        <div className="container">
            <h1 className="bloco-titulo">Como posso ser<br /> um cliente Evolutts?</h1>
            <h2 className="bloco-subtitulo">Confira nossos planos e inscreva-se!</h2>

            <div className="planos">
                <div className="plano" style={{maxWidth: '200px'}}>
                    <p>FREE</p>
                    <div className="valor" style={{fontSize: '29px'}}>
                        Limite 15 Pacientes
                    </div>
                    <div className="opcao">
                        <a className="button cor-4" href="http://sistema.evolutts.com.br/">Escolher</a>
                    </div>
                </div>
                <div className="plano">
                    <p>Mensal</p>
                    <div className="valor">
                        <small>R$</small> 47,70
                    </div>
                    <div className="opcao">
                        <a className="button cor-4" href="http://sistema.evolutts.com.br/">Escolher</a>
                    </div>
                </div>
                <div className="plano">
                    <p>Trimestral</p>
                    <div className="valor">
                        <small>R$</small> 117,70
                    </div>
                    <div className="opcao">
                        <a className="button cor-4" href="http://sistema.evolutts.com.br/">Escolher</a>
                    </div>
                </div>
                <div className="plano destaque">
                    <p>Semestral</p>
                    <div className="valor">
                        <small>R$</small> 207,70
                    </div>
                    <div className="opcao">
                        <a className="button cor-4" href="http://sistema.evolutts.com.br/">Escolher</a>
                    </div>
                </div>
                <div className="plano">
                    <p>Anual</p>
                    <div className="valor">
                        <small>R$</small> 347,70
                    </div>
                    <div className="opcao">
                        <a className="button cor-4" href="http://sistema.evolutts.com.br/">Escolher</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Cliente;